.homepage-what-we-do {
  .col-md-3, .col-md-4 {
      display: flex;
      p {
          margin: auto;
      }
  }
  margin-bottom: 60px;
  p {
      color: $gray;
      font-size: 16pt;
  }
  .col-md-2 {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
          max-width: 100%;
      }
  }
  h2 {
      margin-bottom: 30px;
  }
  @media(max-width: 768px) {
      * {
          // text-align: center !important;
      }
      img {
          margin: 20px 0;
      }
  }
}

.homepage-cloud-stories {
  margin-bottom: 60px;
  h2 {
      margin-top: 30px;
  }
  .row {

  }

  .card {
      border: none;
      @media (max-width: 991px) and (min-width:769px) {
        max-width: 75%;
      }
      @media (max-width: 768px) {
        margin-bottom:100px;

      }
  }
  .card-img-top {
      height: 320px;
      border-bottom: 2px solid $red;
  }
  .card-body {
      position: relative;
      padding-left: 2.25rem;
      &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background-color: $red;
          width: 0;
          height: 0;
        //   border-top: 20px solid $red;
        //   border-left: 20px solid white;
      }
      .card-title {
          margin-bottom: 90px;
          font-weight: bold;
      }
      a.button {
          position: absolute;
          right: 2rem;
          bottom: 1rem;
          @media (max-width: 768px) {
            bottom: 7rem;
          }
      }
  }
}

.homepage-dream-cloud {
  margin-bottom: 60px;
  color: white;
  .jumbotron {
      background-image: url("/img/homepage/dreamcloud.jpg");
      background-size: cover;
  }
  .w-50 {
      display: inline-block;
      &.left {
          float: left;
      }
  }
  h4 {
      margin-bottom: 30px;
      font-size: 1.9rem;
  }
  .button {
      background-color: $pink;
  }
  @media (max-width: 768px) {
      .w-50 {
          width: 100% !important;
      }
      h4 {
          margin: 30px;
      }
      .button {
          display: inline-block;
      }
      * {
          float: none;
          text-align: center;
      }
  }
}

.homepage-how-can-we-help {
  margin-bottom: 60px;
  .inverse {
      background-color: $pink;
      color: white;
      .button:hover {
          background-color: white;
          color: $red;
      }
  }
  .card {
      border-radius: 0;
      .card-body {
          padding: 2rem 2rem 2rem 4rem;
          .card-title {
              margin-bottom: 70px;
          }
          a.button {
              position: absolute;
              right: 0.1rem;
              bottom: 2rem;
          }
      }
  }
}

.section-1 {
    h2 {
        text-align: center;
        color: #faebd7;
        margin-bottom: 5px;
        font-size: 34pt;
    }
    h3 {
        width: 50%;
        border: 2px solid white;
        margin-left: 25%;
        // margin-right: 50%;
        margin-top: 50px;
        color: white;
        text-align: center;
        font-size: 36pt;

        @media (max-width: 768px) {
            margin-left: 0;
            width: 100%;
            font-size: 30pt;
        }
    }
}


.about-section {
    // padding: 0 !important;
    // margin: 0 !important;
    padding: 2rem 1rem;
    overflow: visible !important;
    @media (min-width: 1285px) {
        height: 300px;
    }
    height: 350px;
    @media (max-width: 768px) {
        height: auto;
      }
.about-container {
    overflow: visible;
}
.about-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    // overflow: visible;
    // flex-wrap: wrap;
}
.about-col-1 {
    flex-grow: 1;
    // padding: 20px;
    // padding-left: 45px;
}
.about-col-2 {
    flex-grow: 1;
}
  h1 {
      color: #faebd7;
      font-size: 36pt;
      text-align: center;
      margin-bottom: 35px;
      @media (max-width: 768px) {
        font-size: 30pt;
        }
  }
  h3 {
      color: white;
      text-align: center;
      font-size: 18pt;
  }
  img {
    transform: rotate3d(-4, 1, 1, 45deg);
    @media (min-width: 1285px) {
        // height: 350px;
    }
      @media (max-width: 768px) {
        display: none;
      }
    //   height: 450px;
      width: 95%;

  }
}


// .pasta-plate {
//   width: 100%;
//     height: 200%;
//     /* overflow: visible; */
//     // position: absolute;
//     transform: rotate3d(-4, 1, 1, 45deg);
// }
 .signup-jumbo {
    padding: 2rem 1rem;
 }
.signup-container {
    h3 {
        color: #faebd7;
        // text-align: center;
        margin-bottom: 50px;
    }
    input {
        width: 200px;
    }
    button {
        margin-left: 10px;
        padding: 8px;
    }
}
.signup--row {
  align-items: center;
}

.signup--col {
  text-align: center;
}

.resso-jumbo {
  padding: 0;
}

.ressoConatiner {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 85px;
  justify-items: center;
  text-align: center;
}