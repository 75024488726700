// * {
//   transition: 0.3s;
// }

.padding-top-0 {
  padding-top: 0;
}

@font-face {
  font-family: baciGaramond;
  src: url('/font/EBGaramond-Regular.ttf');
}
body, html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: baciGaramond !important;
  background-color: rgb(171,29,29);
  /* font: normal normal normal 23px/1.4em 'eb garamond',serif; */
}

// Vars
$light-blue: #100F57;
$top-footer: #222a3b;
//$dark-blue: #111726;
$dark-blue: #121725;
$darkest-blue: #8c1827;

// This is not used in brand at all, but it was in the original designs of the website.
// It also looks better, imo.
//$red: #d14e46;
//$red: #8c1827;
$red: rgb(171,29,29);

$pink: #db2446;

$gray: #5e5e5e;



// BG Colors
.bg-red {
  background-color: $red;
}
.bg-pink {
  background-color: $pink;
}
.bg-light-blue {
  background-color: $light-blue;
}
.bg-dark-blue {
  background-color: $dark-blue;
}

// Global classes
.margin-top-60 {
  margin-top: 60px;
}
div.margin-top-100 {
  margin-top: 100px;
}
div.margin-bottom-100 {
  margin-bottom: 100px;
}
.margin-bottom-60 {
  margin-bottom: 60px;
}
.container {
  @media (min-width: 1600px) {
    max-width: 1400px;
  }
}
// .hide-mobile {
//   @media (max-width: 767px) {
//     // display: none;
//     // opacity: 0;
//   }
// }

// Text
// Load poppins font

* {
  font-family: baciGaramond !important;
}


h2 {
  color: black;
  margin-bottom: 40px;
  font-weight: normal;
  font-size: 44px;
}
h3 {
  font-weight: normal;
}
.text-underline {
  text-decoration: underline;
}

// Elements
.circle {
  border-radius: 50%;
  &:hover {
    background: darken($color: $gray, $amount: 10);
  }
}

a:hover {
  color: #d14e46;
}

iframe {
  max-width: 100%;
}

// Side by side widget
.side-by-side {
  margin-bottom: 60px;
  h3 {
    font-size: 1.25rem;
  }
  img {
    max-width: 100%;
    height: auto;
    @media (max-width: 768px) {
      margin-bottom: 60px;
    }
  }
  .col-md-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
  .button {
    margin-top: 20px;
  }
  &.reverse .row {
    @media (min-width: 768px) {
      flex-direction: row-reverse;
    }
  }
  &.has-bg {
    padding: 3rem;
  }
  .bg-light-blue {
    padding: 3em;
    @media (max-width: 767px) {
      margin-bottom: 60px;
    }
    h4 {
        color: white;
        text-align: right;
        line-height: 1.5;
        font-weight: normal;
        font-size: 25px;
    }
}
  &.overlap {
    .col-md-6 {
      overflow: visible;
    }
    img {
      width: 120%;
      @media(max-width: 767px) {
        width: 100%;
        margin-bottom: 20px;
      }
      max-width: unset;
    }
    &.reverse {
      img {
        position: relative;
        left: -20%;
        @media (max-width: 767px) {
          left: 0;
        }
      }
    }
    .has-bg {
      display: flex;
      align-items: center;
      color: white;
      div {
        background-color: $pink;
        padding: 70px;
        @media (max-width: 767px) {
          padding: 2rem;
        }
      }
      h3 {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 24px;
      }
      .button {
        margin-top: 20px;
        display: inline-block;
        background-color: transparent;
        border-color: white;
        color: white;
      }
      &.white {
        color: black;
        div {
          background-color: white;
        }
      }
    }
  }
}

// Buttons
a.button {
  color: white;
  background-color: $red;
  padding: .8em 1.336em;
  font-size: 16px;
  &:hover {
    color: white;
    text-decoration: none;
    background-color: darken($color: $red, $amount: 10);
    svg {
      position: relative;
      transition: left 300ms;
      left: 5px;
    }
  }
  span {
    margin-left: 20px;
    position: relative;
    bottom: 2px;
    transition: 0s;
    svg {
      left: 0;
    }
    * {
      transition: 0s;
    }
  }
  &.inverse {
    background-color: white;
    color: $pink;
    border: 2px solid $pink;
    &:hover {
      background-color: $red;
      color: white;
    }
  }
  &.huge {
    font-size: 36px;
    margin-bottom: 100px;
    font-weight: bold;
    border-radius: 8px;
    background-color: $light-blue;
    color: white;
    display: inline-block;
  }
}