.jumbotron {
  border-radius: 0;
  background-position: center;
  background-size: cover;
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    //background-color: rgba($color: $dark-blue, $alpha: 0.6);
    background: rgb(17,23,38);
    background: linear-gradient(180deg, rgba(17,23,38,0.6) 20%, rgba(17,23,38,0.2) 100%);
  }
  video {
    position: absolute;
    min-height: 100%;
    // padding-top: 150px;
    min-width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

  }
  &--header.black {
    h1, h2, p {
      color: black !important;
    }
  }
  &--header .container {
    position: relative;
    margin-top: 400px;
    margin-bottom: 50px;
    margin-left: 0;
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @media (min-height: 810px)  {
      margin-top: 380px;
    }
    @media (min-height: 666px) and (max-height: 809px) {
      margin-top: 260px;
    }
    @media (min-height: 568px) and (max-height: 665px) {
      margin-top: 170px;
    }
    @media (max-height: 567px) {
      margin-top: 70px;
    }
    // @media (min-width: 768px) and (max-width: 1400px) {
    //   left: 5rem;
    // }
    .baci-logo {
     position: relative;
      max-height: 150px;
      height: 100%;
      // transform: translate(-100%);
    }
    h1 {
      color: white;
      font-size: 4rem;
      @media (max-width: 767px) {
        font-size: 3rem;
      }
    }
    h2 {
      // display: inline-block;
      max-width: 50% !important;
      @media (max-width: 767px) {
        max-width: 100% !important;
        text-align: center !important;
      }
      color: white;
      margin-bottom: 0;
      @media (max-width: 1200px) {
        max-width: 100%;
      }
      // @media (max-width: 767px) {
      //   text-align: center;
      // }
    }
    p {
      color: white;
      font-size: 1.5rem;
      max-width: 50%;
      margin-top: 20px;
    }
  }
  .jumbotron-button {
    margin-top: 40px;
    @media (max-width: 767px) {
      text-align: center;
    }
    a {
      display: inline-block;
      background-color: transparent;
      color: white;
      border-color: white;
      &:hover {
        background-color: white;
        border-color: pink;
        color: pink;
      }
    }
  }
  @media (max-width: 768px) {
    .container {
      h2 {
        max-width: 100%;
        // text-align: center;
        font-size: 2rem;
      }
      p {
        max-width: 100%;
      }
    }
  }
}

.jumbotron-video-header {
  height: 80vh;
}
