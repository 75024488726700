footer {
  color: white;
  @media (max-width: 768px) {
      text-align: center;
  }

  a {
      color: white;
  }

  .nav-link {
      padding: 1px 0;
  }

  .footer-upper {
      background-color: $top-footer;
      padding: 40px 0 60px;
      border-bottom: 2px solid #bbb;
      h5 {
          font-size: 1.7rem;
      }
      .col-md-auto {
          @media (max-width: 768px) {
              margin-bottom: 1.5rem;
          }
          @media (min-width: 768px) {
              margin: 0 2rem;
          }
      }
  }

  .footer-middle {
      position: relative;
      height: 0;
      bottom: 32px;
      .row {
          justify-content: center !important;
      }
      .col-1 {
          text-align: center;
          min-width: 60px;
          @media (max-width: 768px) {
              margin: 0 10px;
          }
      }
      .circle {
          display: inline-block;
          height: 60px;
          width: 60px;
          a {
              display: inline-block;
              height: 40px;
              width: 40px;
              margin-top: 10px;
          }
      }
  }

  .footer-lower {
      padding: 60px 0 40px;
      background-color: $dark-blue;
      @media (max-width: 767px) {
          .row >div {
              margin-bottom: 60px;
          }
          // Hubspot form
          iframe {
              max-width: 70%;
              margin: 0 auto;
          }
      }
      h3 {
          font-size: 1.5rem;
          margin: 0 0 1.5rem 0;
      }
      .footer-what-we-do {
          margin-bottom: 40px;
      }
      p {
          margin-bottom: 0;
      }
      .footer-subscribe-p {
          margin-bottom: 1rem !important;
      }
      .footer-subscribe-h3 {
          margin: 0 0 1.5rem 0;
      }
      .footer-lower--row {
          justify-content: space-between;
          align-items: center;
          align-content: center;
          @media (max-width: 768px) {
            justify-content: center;
          }
      }

      .footer-lower--container {
          max-width: 90%;
          margin:   auto;
      }

      .footer-lower-middle {
          .button {
              display: inline-block;
              margin-bottom: 40px;
          }
          .aws-partner {
              display: block;
          }
          img {
              max-width: 100%;
              height: auto;
          }
      }
      .footer-address-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // position: relative;
          // text-align: right;
          @media (max-width: 768px) {
              margin-top: 2rem;
              text-align: center;
          }
      }
      .footer-logo {
          max-width: 250px;
          margin-bottom: 20px;
      }
      .tel {
          font-size: 1.8rem;
          @media (min-width: 768px) {
              // position: absolute;
              font-size: 1.4rem;
              right: 10px;
              bottom: 0;
              margin-top: 15px;
          }
      }
  }

  .footer-bottom {
      // padding: 14px 0;
      .container {
        max-width: 100% !important;
      }

      font-size: 0.9rem;
      background-color: $red;
      @media (max-width: 768px) {
          .text-right {
              margin-top: 10px;
              text-align: center !important;
          }
      }
      a:hover {
          color: white;
      }
  }
}

.insta {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  // background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.fb {
  background: #3B5998;
}

.contact-map {
  height: 35vh;
  width: 50vw;
  max-width: 100%;
  margin: 1em 0;
  border-radius: 0.5em;
}

@media (max-width: 768px) {
  .contact-map {
    height: 40vh;
    width: 80vw;
    max-width: 100%;
    margin: 1em 0;
    border-radius: 0.5em;
  }
}

.madeby {
  opacity: 0;
  margin-left: auto;
}